import 'zone.js/plugins/zone-error';
import { IEnvironment } from '@am/_types';
import { IBaseEnvironment } from '@arturin/core';

const backEndUrl = 'https://be-testbyvlada.arturhaunt.com';

export const baseEnvironment: IBaseEnvironment = {
  accessManagerUrl: 'https://login-testbyvlada.arturhaunt.com',
  baseDomain: 'arturhaunt.com',
  backEndUrl,
  successBackOfficeUrl: backEndUrl,
  enableAngularProdMode: false,
};

export const environment: IEnvironment = {
  ...baseEnvironment,
  apiUrl: `${baseEnvironment.backEndUrl}/`,
  recaptchaKey: '6LfYUZQUAAAAAMxJi5Y0tqRVyOhKZ0nh_079M-cB',
};
